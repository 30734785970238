<script setup>
import { provide, ref } from 'vue'

const activeItem = ref(undefined)

provide('activeItem', activeItem)
</script>

<template>
  <div class="accordion flex flex-col space-y-4">
    <slot />
  </div>
</template>

<style scoped></style>
