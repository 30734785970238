<script setup>
import { computed, inject } from 'vue'

const activeItem = inject('activeItem')
const index = inject('index')

const isActive = computed(() => activeItem.value === index)
</script>

<template>
  <Transition name="accordion" mode="out-in">
    <div v-if="isActive" class="accordion-body" :class="{ active: isActive }">
      <slot />
    </div>
  </Transition>
</template>

<style scoped>
.accordion-body {
  @apply py-8;
}

.accordion-enter-active {
  animation: slide-in 0.15s ease;
}
.accordion-leave-active {
  animation: slide-in 0.15s ease reverse;
}

@keyframes slide-in {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
